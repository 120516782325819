.cover {
  align-items: center;
  justify-content: space-between;
  background-color: darkgrey;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  padding: 2.5rem;
  position: relative;
  scroll-snap-align: start;
  z-index: 100;
  /* preferred dvh, fallback vh */
  height: 100vh;
  height: 100dvh;
}

.slideShowContainer,
.image {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.slideShowContainer {
  z-index: -1;
}

.image {
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity linear 2s, transform linear 10s;
  background-position: 50% 35%;
}

.cover_minimized {
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.cover_minimized .title {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cover_minimized {
  font-size: 0px;
  opacity: 0;
}

.text {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.slideshowButtonBar {
  display: inline-flex;
  flex-direction: column;
  margin-right: -3rem;
}

.title {
  color: white;
  font-family: "Charmonman";
  text-shadow: 0 1px rgba(0, 0, 0, 0.5);
  transition: all 400ms ease-in-out;
  white-space: break-spaces;
}

.title {
  font-size: 2.5rem;
}

@media screen and (min-width: 960px) {
  .cover {
    scroll-snap-align: initial;
  }
}
