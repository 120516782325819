.root {
  background-color: lightgray;
  border-radius: 2px;
  bottom: 0;
  color: black;
  font-family: monospace;
  font-size: 0.75rem;
  padding: 0.5rem;
  position: fixed;
}
