.root {
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: 0.5rem;
}

.reverse {
  flex-direction: row-reverse;
}

.event {
  flex-grow: 1;
  display: grid;
  grid-template-rows: 1fr 1px 1fr;
  justify-items: center;
}

.divider {
  border: none;
  border-top: 1px dotted var(--GREEN);
  margin: 0;
  width: 100%;
}

.what,
.where {
  color: var(--GREEN);
  padding-inline-end: 0.5rem;
}

.what {
  align-self: flex-end;
  font-weight: 500;
}

.where {
  align-self: flex-start;
  color: var(--GREEN);
  font-size: 0.875rem;
}

.timeFrame {
  color: var(--DARK-GREEN);
}

.what,
.timeFrame,
.where {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  word-wrap: break-word;
}
