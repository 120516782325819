.agenda {
  /* Reset - start */
  list-style: none;
  margin-block-end: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  padding-block-end: 0;
  padding-block-start: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
  /* Reset - end */
  display: grid;
  flex-grow: 1;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  margin-block-start: 1rem;
  position: relative;
}

.agenda::before,
.agenda::after {
  background-color: var(--GREEN);
  border-radius: 50%;
  content: "";
  height: 6px;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 6px;
}

.agenda::before {
  top: 0;
}

.agenda::after {
  bottom: 0;
}

.imgRes {
  height: 2.5rem;
}