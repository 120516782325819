.root {
  align-items: center;
  box-sizing: border-box;
  color: var(--DARK-GREEN);
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2rem;
  scroll-snap-align: start;
  /* preferred svh, fallback vh */
  min-height: 100vh;
  min-height: 100svh;
}

.root:last-of-type {
  scroll-snap-align: none;
}
