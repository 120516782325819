.heading {
  align-items: center;
  color: var(--DARK-GREEN);
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  font-family: "Charmonman";
  text-align: center;
}
