.root,
.image {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.root {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  z-index: -1;
}

.image {
  transform: none;
  transition: opacity linear 2s, transform linear 12s;
}

.buttonBar {
  display: inline-flex;
  padding: 0.25rem;
}
