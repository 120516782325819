.content {
  display: contents;
  background-color: var(--IVORY);
}

.root {
  overflow: auto;
  /* preferred dvh, fallback vh */
  height: 100vh;
  height: 100dvh;
  background-color: var(--IVORY);
}

@media screen and (min-width: 960px) {
  .root {
    display: grid;
    grid-template-columns: 1fr minmax(500px, 40%);
    scroll-snap-type: none;
  }

  .cover {
    grid-column: 1;
    position: initial;
    height: auto;
  }

  .content {
    display: block;
    grid-column: 2;
    scroll-snap-type: y proximity;
    overflow-y: auto;
  }
}
